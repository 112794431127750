import React from "react"
import UserProvider from "./src/context/userContext"

import "bootstrap/dist/css/bootstrap.min.css"
import "react-datepicker/dist/react-datepicker.css"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
  <UserProvider>{element}</UserProvider>
)
