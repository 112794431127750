import React, { createContext, useEffect, useState } from "react"
import bgMusic from "../../static/sounds/bg_music.mp3"

const defaultContext = {
  nric: "", //unique id
  name: "",
  email: "",
  password: "",
  mobile_number: "",
  casual_labour_role: false,
  cleaning_specialist_role: false,
  nric_front: null, //base64 image
  nric_back: null, //base64 image
  face: null, //base64 image
  birth_date: "",
  gender: "",
  nationality: "",
  singapore_pr: "",
  civil_status: "",
  race: "",
  religion: "",
  singapore_pr_date: null,
  no_of_children: 0,
  address: "",
  residential_number: "",
  residential_phone_no: "",
  nok_agree: false,
  person_in_charge_id: null,
  nok_name: "",
  nok_relationship: "",
  nok_birth_date: null,
  nok_residential_address: "",
  nok_residential_number: "",
  nok_mobile_number: "",
  highest_edu_school: "",
  highest_edu_years: "",
  highest_edu_qualification: "",
  current_edu_school: "",
  current_edu_years: "",
  current_edu_qualification: "",
  currentPage: 1,
  appendix1_option: "No",
  appendix1_details: "",
  appendix2_option: "No",
  appendix2_details: "",
  appendix3_option: "No",
  appendix3_details: "",
  appendix4_option: "No",
  appendix4_details: "",
  substitute_name: "",
  substitute_contractor_code: "",
  substitute_contact_number: "",
  is_foreign_student: "No",
  student_card_expiry_date: null,
  salary_payment_method: "Cash",
  feedback: "",
  audio: {},
  signature: null,
}

export const UserContext = createContext(defaultContext)
const UserProvider = ({ children }) => {
  const API_URL = process.env.GATSBY_API_URL

  const [user, setUser] = useState({
    nric: "", //unique id
    name: "",
    email: "",
    password: "",
    mobile_number: "",
    casual_labour_role: false,
    cleaning_specialist_role: false,
    nric_front: null, //base64 image
    nric_back: null, //base64 image
    face: null, //base64 image
    birth_date: "",
    gender: "",
    nationality: "",
    singapore_pr: "",
    civil_status: "",
    race: "",
    religion: "",
    singapore_pr_date: null,
    no_of_children: 0,
    address: "",
    residential_number: "",
    residential_phone_no: "",
    nok_agree: false,
    person_in_charge_id: null,
    nok_name: "",
    nok_relationship: "",
    nok_birth_date: null,
    nok_residential_address: "",
    nok_residential_number: "",
    nok_mobile_number: "",
    highest_edu_school: "",
    highest_edu_years: "",
    highest_edu_qualification: "",
    current_edu_school: "",
    current_edu_years: "",
    current_edu_qualification: "",
    currentPage: 1,
    appendix1_option: "No",
    appendix1_details: "",
    appendix2_option: "No",
    appendix2_details: "",
    appendix3_option: "No",
    appendix3_details: "",
    appendix4_option: "No",
    appendix4_details: "",
    substitute_name: "",
    substitute_contractor_code: "",
    substitute_contact_number: "",
    is_foreign_student: "No",
    student_card_expiry_date: null,
    salary_payment_method: "Cash",
    feedback: "",
    audio: {},
    signature: null,
    isKeyboardActive: true,
    isSoundActive: true,
  })

  const saveUser = data => {
    setUser({ ...user, ...data })
    console.log(data)
  }

  const saveDraftUser = data => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "access-control-allow-origin, Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
      },
      body: JSON.stringify(data),
    }

    fetch(API_URL + "/draft-register-kiosk", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw response
        }
        return response.json()
      })
      .then(data => {
        console.log("Done saving draft, ", data)
      })
      .catch(err => {
        err.text().then(errorMessage => {
          console.warn(errorMessage)
        })
      })
  }

  return (
    <UserContext.Provider value={{ user, saveUser, saveDraftUser }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
